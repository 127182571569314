import 'bootstrap';
import 'slick-carousel';

(function ($) {
	// On Dom Ready
	$(function () {
		// .diversity-champions Carousel
		$('.diversity-champions .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			rows: 0,
		});

		// .featured-recruiters Carousel
		$('.featured-recruiters .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: false,
			rows: 0,
			fade: true,
		});

		// .employer-of-the-week Carousel
		$('.employer-of-the-week .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			rows: 0,
			fade: true,
		});

		// /jobs-by-sector Carousel
		$('.jobs-by-sector .the-carousel').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			dots: false,
			arrows: false,
			infinite: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: false,
			rows: 0,
		});

		// Toggle advanced search
		$('.adv-search-button').on('click', function (e) {
			e.preventDefault();
			$(
				'.hero-banner .overlay .search-form .adv-search-textbox-wrapper'
			).slideToggle();
		});

		// Toggle mobile menu
		$('.menu-open').on('click', function () {
			$('.mobile-menu-wrapper').addClass('active');
			$(this).addClass('is-active');
			$('body').addClass('noscroll');
		});
		$('.menu-close').on('click', function () {
			$('.mobile-menu-wrapper').removeClass('active');
			$('.menu-open').removeClass('is-active');
			$('body').removeClass('noscroll');
		});

		// Toggle mobile search
		$('.search-open').on('click', function (e) {
			e.preventDefault();
			$('.mobile-search').slideToggle();
		});

		// Add modal open class to body after modal is opened
		$('.modal').on('show.bs.modal', function () {
			setTimeout(function () {
				$('body').addClass('modal-open');
			}, 800);
		});
	});

	// After page loaded completely
	$(window).on('load', function () {
		$('.mobile-menu-wrapper').addClass('initialized');
	});

	$(window).on('resize', function () {
		$('.menu-close').trigger('click');
	});

	// Jobs by email form
	$('#frmJobsByEmail').on('submit', function (e) {
		e.preventDefault();
		var email = $('#signup_email').val();
		$('#registerModal').modal();
		if (email !== '') {
			$('#formSignup').find('input[name="email"').val(email);
		}
	});

	// Submit Login
	$('#formLogin').on('submit', function (e) {
		e.preventDefault();
		if (this.username.value == '') {
			alert('Please enter your email address');
			this.username.focus();
			return false;
		}
		if (this.password.value == '') {
			alert('Please enter your Password');
			this.password.focus();
			return false;
		}

		// enable this only when live
		if (
			!grecaptcha.getResponse($('#RecaptchaFieldLogin').attr('data-widget-id'))
		) {
			alert('Please verify you are not a robot.');
			return false;
		}

		$.ajax({
			type: 'POST',
			url: '/include/login_action.php?action=submit',
			data: $('#formLogin').serialize(),
			success: function (response) {
				if (response.login && response.login == 'failed') {
					$('#loginResponse').html(
						'<div class="mb-4 alert alert-danger small">Invalid Email Address or Password</div>'
					);
				} else if (response.login && response.login == 'success') {
					$('#loginResponse').html(
						'<div class="mb-4 alert alert-success small">Logged in successfully. <br/>Please wait, You will be redirected..</div>'
					);
					location.href = response.redirect_to;
				} else {
					$('#loginResponse').html(
						'<div class="mb-4 alert alert-danger small">Unexpected server error occured</div>'
					);
				}
			},
		});
	});

	// Submit Forgot Password
	$('#formForgotPassword').on('submit', function (e) {
		e.preventDefault();
		if (document.formForgotPassword.username.value == '') {
			alert('Please enter your email address');
			document.formForgotPassword.username.focus();
			return false;
		}

		// enable this only when live
		if (
			!grecaptcha.getResponse(
				$('#RecaptchaFieldForgotPassword').attr('data-widget-id')
			)
		) {
			alert('Please verify you are not a robot.');
			return false;
		}

		$.ajax({
			type: 'POST',
			url: '/include/forgot_password_action.php?action=submit',
			data: $('#formForgotPassword').serialize(),
			success: function (response) {
				if (response.result && response.result == 'failed') {
					$('#forgotPasswordResponse').html(
						'<div class="mb-4 alert alert-danger small">' +
							response.message +
							'</div>'
					);
				} else if (response.result && response.result == 'success') {
					$('#forgotPasswordResponse').html(
						'<div class="mb-4 alert alert-success small">' +
							response.message +
							'</div>'
					);
				} else {
					$('#forgotPasswordResponse').html(
						'<div class="mb-4 alert alert-danger small">Unexpected server error occured</div>'
					);
				}
			},
		});
	});

	// Signup Form
	$('#formSignup').on('submit', function (e) {
		e.preventDefault();
		if (document.formSignup.name.value == '') {
			alert('Please Enter Your Name');
			document.formSignup.name.focus();
			return false;
		}

		if (
			document.formSignup.email.value == '' ||
			document.formSignup.email.value != ''
		) {
			var emailstr;
			emailstr = document.formSignup.email.value;

			if (emailstr == '') {
				alert('Please Enter E-mail Address');
				document.formSignup.email.focus();
				return false;
			}

			if (emailstr != '' && !ValidateEmail(emailstr)) {
				alert('Please Enter A Valid E-mail Address');
				document.formSignup.email.focus();
				return false;
			}
		}

		if (document.formSignup.password.value == '') {
			alert('Please enter your password');
			document.formSignup.password.focus();
			return false;
		}
		if (document.formSignup.password2.value == '') {
			alert('Please repeat your password');
			document.formSignup.password2.focus();
			return false;
		}
		if (
			document.formSignup.password.value != document.formSignup.password2.value
		) {
			alert('Password and Password confirm should be matched.');
			document.formSignup.password.focus();
			return false;
		}
		if (document.formSignup.checkbox2.checked == false) {
			alert('You should agree with the terms.');
			return false;
		}

		// enable this only when live
		if (
			!grecaptcha.getResponse($('#RecaptchaFieldSignup').attr('data-widget-id'))
		) {
			alert('Please verify you are not a robot.');
			return false;
		}

		$.ajax({
			type: 'POST',
			url: '/include/register_action.php?action=submit',
			data: $('#formSignup').serialize(),
			success: function (response) {
				if (response.result && response.result == 'failed') {
					$('#signupResponse').html(
						'<div class="mb-4 alert alert-danger small">' +
							response.message +
							'</div>'
					);
				} else if (response.result && response.result == 'success') {
					$('.register-form-wrapper').hide();
					$('#signupResponse').html(
						'<div class="mb-4 alert alert-success small">' +
							response.message +
							'</div>'
					);
				} else {
					$('#signupResponse').html(
						'<div class="mb-4 alert alert-danger small">Unexpected server error occured</div>'
					);
				}
			},
		});
	});

	// validate password
	var myInput = document.getElementById('ct_password');
	var letter = document.getElementById('pass_letter');
	var capital = document.getElementById('pass_capital');
	var number = document.getElementById('pass_number');
	var length = document.getElementById('pass_length');
	var letter_valid = false;
	var capital_valid = false;
	var number_valid = false;
	var length_valid = false;

	// When the user starts to type something inside the password field
	myInput.onkeyup = function () {
		validate_password();
	};
	function validate_password() {
		$('#pass_message').show();
		// Validate lowercase letters
		var lowerCaseLetters = /[a-z]/g;

		if (myInput.value.match(lowerCaseLetters)) {
			letter.classList.remove('invalid');
			letter.classList.add('valid');
			letter_valid = true;
		} else {
			letter.classList.remove('valid');
			letter.classList.add('invalid');
			letter_valid = false;
		}

		// Validate capital letters
		var upperCaseLetters = /[A-Z]/g;
		if (myInput.value.match(upperCaseLetters)) {
			capital.classList.remove('invalid');
			capital.classList.add('valid');
			capital_valid = true;
		} else {
			capital.classList.remove('valid');
			capital.classList.add('invalid');
			capital_valid = false;
		}

		// Validate numbers
		var numbers = /[0-9]/g;
		if (myInput.value.match(numbers)) {
			number.classList.remove('invalid');
			number.classList.add('valid');
			number_valid = true;
		} else {
			number.classList.remove('valid');
			number.classList.add('invalid');
			number_valid = false;
		}

		// Validate length
		if (myInput.value.length >= 8) {
			length.classList.remove('invalid');
			length.classList.add('valid');
			length_valid = true;
		} else {
			length.classList.remove('valid');
			length.classList.add('invalid');
			length_valid = false;
		}

		if (
			letter_valid == true &&
			capital_valid == true &&
			number_valid == true &&
			length_valid == true
		) {
			return true;
		} else {
			return false;
		}
	}
})(jQuery);

function ValidateEmail(str) {
	if (
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
			str
		)
	) {
		return true;
	}
	return false;
}
